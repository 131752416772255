body {
  background-color: #eee;
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  /*background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);*/

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /*background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);*/
  background: -webkit-linear-gradient(to right, #060507 0%, #14374D 100%);
  background: linear-gradient(to right, #060507 0%, #14374D 100%);
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-left-radius: .3rem;
    /* border-bottom-left-radius: .3rem; */
  }
}

/*
#version:hover {
  cursor: not-allowed;
}
*/

.config-col {
  position: relative;
  margin-bottom: 20px;
}

.progress-prompt {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 230px;
}

.warning-box {
  color: #b38600;
  background-color: #fcebb8;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .5);
  text-align: center;
  padding: 10px;
}

.error-box {
  color: #D8000C;
  background-color: #FFD2D2;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .5);
  text-align: center;
  padding: 10px 0;

}

.error-box p {
  margin-bottom: 0;
}

.error-box span {
  font-size: 12px;
}

.advanced-option {
  text-align: left;
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: height 300ms ease-in-out;
}

.display-column {
  display: flex;
  flex-direction: column;
}

.mr10 {
  margin-right: 10px;
}

.active:hover {
  font-weight: bold;
}

.expanded {
  height: 100px;
}

.shaves-ticks {
  display: flex;
  justify-content: space-between;
}

.form-label-link {
  color: #000000;
  text-decoration: none;
}

.btn-radio {
  width: 160px;
  text-align: center;
  font-size: smaller;
}

.btn-radio-left {
  border-radius: 0.25rem 0 0 0.25rem;
}

.btn-radio-right {
  border-radius: 0 0.25rem 0.25rem 0;
}

.btn-radio-group {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 400px) {
  .btn-radio-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .btn-radio {
    width: 100%;
  }
}

